


























































































































































































































































































































































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import TopBar from '@/components/organisms/o-top-bar.vue'
import { Booking } from '@/api/interfaces/booking'
import { BookingStatus } from '@/api/enums/bookingStatus'
import { hybridAcademyTrainingTypes } from '@/config-constantes'
import { TrainingType } from '@/api/interfaces/trainingtype'
import { formatDate } from '@/plugins/format-date'

export default defineComponent({
  name: 'Booking',
  components: {
    TopBar,
    ScrollTop: () => import('@/components/base/ScrollTop.vue'),
    SelectCarDealer: () => import('@/components/molecules/select-search/carDealer.vue'),
    SelectTrainingSessionStatus: () => import('@/components/molecules/select/trainingSessionStatus.vue'),
    ConfirmModal: () => import('@/components/molecules/dialog/confirmModal.vue'),
  },
  data () {
    return {
      title: this.$t('booking.booking'),
      cancelationConfirmationOpened: false,
      validationConfirmationOpened: false,
      isCancelationLoading: false,
      isValidationLoading: false,
      canceled: BookingStatus.CANCELED,
      headersTrainingSession: [
        {
          align: 'center',
          text: this.$t('training_session.list.headers.link'),
          value: 'booking.trainingSession.startAt',
        },
      ],
    }
  },
  computed: {
    ...mapState('booking', {
      booking: 'booking',
    }),
    ...mapState('trainingType', {
      trainingTypesList: 'list',
    }),
    idBooking () {
      return this.$route.params.idBooking || null
    },
    showTraineeAccess () {
      return (this.booking as Booking).needTraining
    },
    showTraineeSearch () {
      return (this.booking as Booking).bookingStatus === BookingStatus.PENDING
    },
  },
  async created () {
    await this.init()
  },
  watch: {
    idBooking () {
      this.init()
    },
  },
  methods: {
    ...mapActions('booking', {
      cancel: 'cancel',
      validate: 'validate',
      associateTrainees: 'associateTrainees',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    async init () {
      if (this.idBooking) {
        if (
          !this.booking ||
          (this.booking as Booking).id !== parseInt(this.idBooking as string)
        ) {
          await this.loadBookingById(this.idBooking)
        }
      }
    },
    ...mapActions('booking', {
      loadBookingById: 'loadById',
    }),
    returnPrevious (): void {
      this.$router.push({ name: 'Booking List' })
    },
    async cancelBooking (): Promise<void> {
      this.isCancelationLoading = true
      await this.cancel(this.booking.id)
      this.sendSuccessMessage('booking.canceled')
      this.isCancelationLoading = false
      this.returnPrevious()
    },
    async validateBooking (): Promise<void> {
      this.isValidationLoading = true
      await this.validate(this.booking.id)
      this.isValidationLoading = false
      this.sendSuccessMessage('booking.validated')
      this.returnPrevious()
    },
    redirectOnTrainee () : void {
      this.associateTrainees()
      const trainingTypeIri =
        this.trainingTypesList
          .filter((trainingType: TrainingType) => hybridAcademyTrainingTypes.includes(trainingType.code as string))
          .map((trainingType: TrainingType) => trainingType['@id'] as string)
      this.$router.push({
        name: 'Trainee List',
        query: { trainingType: trainingTypeIri },
      })
    },
    goToTrainingSessionForm () {
      if (this.booking.trainingSession.logisticSessions && this.booking.trainingSession.logisticSessions[0].id) {
        this.$router.push({
          name: 'LogisticSession Edit',
          params: { idLogisticSession: this.booking.trainingSession.logisticSessions[0].id.toString() },
        })
      }
    },
    formatDeliveryDate (): string {
      return this.booking.deliveryDate ? formatDate(this.booking.deliveryDate) : ''
    },
  },
})
